
<template>
    <div>
        <el-drawer :visible.sync="newDialog" size="600px" :with-header="false" direction="btt" :before-close="handleClose" :show-close="true" :wrapperClosable="true" :append-to-body="true">
            <div class="detail_cont">
                <div>
                    <div class="phone_title phone_title_one">For more detail information about the company you searched, please go to Company Search’s Platform</div>
                    <div class="phone_title">CSI Platform offers?</div>

                    <ul class="phone_suobox">
                        <li class="phone_text" v-for="(item, index) in menuunitlist" :key="index">
                            
                            {{ item.title }}
                            <b style="float: right;">√</b>
                        </li>
                    </ul>
                </div>

                <div class="phone_flex">
                    <el-button class="btn_export">Go to CSI Platform</el-button>
                    <el-button class="btn_export" @click="onSubmitquxiao()">Not Now</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

  <script>
export default {
    props: {
        suoClose: {
            type: Boolean,
            //   default: false,
        },
        obj: {
            type: Object,
        },
        update: {
            type: Object,
        },
    },
    data() {
        return {
            //page: this.currentPage

            token: sessionStorage.getItem('token'),
            menuunitlist: [
                { title: 'Search companies', units: '1' },
                { title: 'Search products', units: '10' },
                { title: 'Search trade records', units: '1' },
                { title: 'Company credit ratings', units: '10' },
                { title: 'Search country macro economic data', units: '1' },
                { title: 'Ownership structure', units: '1' },
                { title: 'Search news', units: '1' },
                { title: 'Search sanctions', units: '1' },
                { title: 'Data services', units: '10' },
                 { title: 'Personal center and recharge', units: '10' },
            ],
        };
    },
    computed: {
        newDialog: {
            get() {
                return this.suoClose;
            },
            set() {},
        },
        isPagin: {
            get() {
                let status = sessionStorage.getItem('token') ? false : true;
                console.log('isPagin', status);
                return status;
            },
            set() {},
        },
    },
    destroyed() {
        // bus.$off('handleShowLogin');
    },
    methods: {
        handleClose() {
            this.newDialog = false;
            this.$emit('closeCloseDialog', false);
        },
        //关闭弹窗
        closeDialog() {
            this.$emit('closeCloseDialog', false);
        },
        onSubmitkoudian() {
            // console.log(`this.$pa`, );
            this.newDialog = false;
            this.$emit('closeCloseDialog', false);
            //  this.$emit('closeCloseDialog1', true);
        
        },
        onSubmitquxiao() {
            this.newDialog = false;
            this.$emit('closeCloseDialog', false);

        },
    },
};
</script>
  <style scoped>
.detail_cont {
    max-height: 85vh;
    overflow: auto;
    padding: 20px 20px 20px;
}
.detail_cont_text {
    font-size: 14px;
    color: #022955;
}
.dialog_normalsuo {
    width: 720px;
    background: #ffffff;
    border-radius: 20px;
    /* margin-top: 20vh !important; */
}
.dialog_normalsuo .el-dialog__body {
    padding: 30px;
}
.phone_text {
    font-size: 14px;
    width: 100%;
    line-height: 35px;
    color: #022955;
     border-bottom: 1px solid #ccc;
}
.phone_text b {
    color: rgb(18, 144, 201);
    margin-right: 6px;
    font-size: 14px;
}
.phone_suobox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 0px;
    margin: 10px 0px;

}
.phone_title {
    font-size: 14px;
    margin: 10px 13%;
    text-align: center;
}
.phone_flex {
    display: flex;
    justify-content: space-between;
}
.phone_flex .btn_export {
    width: 160px;
    border-radius: 30px;
}
.phone_flex .el-button + .el-button {
    margin-top: 0px !important;
    margin-left: 0px;
}
.phone_title_one{
    font-size: 20px !important;
}
</style>
  





