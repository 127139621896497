<template>
    <div>
        <div ref="form" class="table-biao computer_right_cont_box">
            <div class="thead flex-col">
                <div class="bd11 flex-col">
                    <div class="block13 flex-row">
                        <span class="td-width">{{ untia }}</span>
                        <span class="td-width zhongjian" v-for="(item, index) in years" :key="index">
                            <span class="td-width-span td-width-txt">{{ item }}</span>
                            <span class="td-width-span">{{ $route.name == 'CreditAssessment' ? 'Change' : 'Change (%)' }}</span>
                        </span>
                        <span class="thead-img" @click="createDialog = true"><img src="@/assets/img/rili.png" alt="" /></span>
                    </div>
                </div>
            </div>
            <div class="table-tbody">
                <div class="tbody flex-col" v-for="(items, index) in profitablitylist" :key="index">
                    <div class="bd11 flex-col">
                        <div class="block13 flex-row">
                            <span class="td-width">
                                {{ items.name }}
                                <span></span>
                            </span>
                            <span class="td-width zhongjian" v-for="(item, index) in items.lists" :key="index" v-show="indexlist.includes(index)">
                                <span class="td-width-span td-width-txt">{{ item.value }}</span>
                                <span class="td-width-span">
                                    <span>{{ $route.name == 'CreditAssessment' ? (item.changes == 'n.a.' ? ' ' : '') : item.changes }}</span>
                                    <img style="height: 20px; width: 15px" v-if="$route.name == 'CreditAssessment' ? (item.status == '0' ? true : false) : false" src="../../../../assets/img/henggang.png" alt="" />
                                    <img v-if="item.status == '1'" src="@/assets/img/shangshen.png" alt="" />
                                    <img v-if="item.status == '2'" src="@/assets/img/xiajiang.png" alt="" />
                                </span>
                            </span>
                            <span style="height: 30px; width: 30px"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 手机端 -->
        <div ref="form" class="table-biao phone_page_bg">
            <div class="thead flex-col">
                <div class="bd11 flex-col">
                    <div class="block13 flex-row">
                        <span class="td-width">{{ untia }}</span>
                        <span class="td-width" v-for="(item, index) in years" :key="index">
                            <span class="td-width-span td-width-txt" style="justify-content: end">{{ item }}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="table-tbody">
                <div class="tbody flex-col" v-for="(items, index) in profitablitylist" :key="index">
                    <div class="bd11 flex-col">
                        <div class="block13 flex-row">
                            <span class="td-width">
                                {{ items.name }}
                                <span></span>
                            </span>
                            <span class="td-width" v-for="(item, index) in items.lists" :key="index" v-show="indexlist.includes(index)">
                                <span class="td-width-span td-width-txt" style="justify-content: end">{{ item.value }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="phone_page_bg">
            <div class="phone_flex">
                <el-button class="btn_export" @click="More()">More</el-button>
            </div>
        </div>
        <el-dialog :visible.sync="createDialog" center :close-on-click-modal="false" custom-class="dialog_normal" :append-to-body="true">
            <div class="detail_cont">
                <div class="dialog_detail_title">Please select the date you want to display</div>
                <el-checkbox-group v-model="checkedCities" :max="3">
                    <el-checkbox v-for="city in yearslist" :label="city" :key="city">{{ city }}</el-checkbox>
                </el-checkbox-group>

                <div class="btn_box flex_center_between_box">
                    <button
                        class="btn_export"
                        @click="
                            createDialog = false;
                            addDialog = true;
                        "
                    >
                        Cancel
                    </button>
                    <button class="btn_export" @click="checkYear">Confirm</button>
                </div>
            </div>
        </el-dialog>
        <Phonetocsi class="phone_page_bg" :suoClose="moreclose" @closeCloseDialog="closmoreDialog($event)"></Phonetocsi>
    </div>
</template>
<script>
import Phonetocsi from '@/components/phonetocsi.vue';
export default {
    components: { Phonetocsi },
    props: {
        profitablitylist: {
            type: Array,
            default: function () {},
        },
        yearslist: {
            type: Array,
            default: function () {},
        },
        untia: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            indexlist: [0, 1, 2],
            defatindex: 0,
            profitablitylist2: [
                {
                    lists: [
                        {
                            change: '',
                            value: '',
                        },
                        {
                            change: '',
                            value: '',
                        },
                        {
                            change: '',
                            value: '',
                        },
                    ],
                    name: 'Return on Equity:',
                },
                {
                    lists: [
                        {
                            change: '',
                            value: '',
                        },
                        {
                            change: '',
                            value: '',
                        },
                        {
                            change: '',
                            value: '',
                        },
                    ],
                    name: 'Return on Assetsd:',
                },
                {
                    lists: [
                        {
                            change: '',
                            value: '',
                        },
                        {
                            change: '',
                            value: '',
                        },
                        {
                            change: '',
                            value: '',
                        },
                    ],
                    name: '',
                },
            ],
            createDialog: false,
            years: ['', '', ''],
            checkedCities: ['', '', ''],
            windows: window,
            moreclose: false,
        };
    },
    created() {},

    watch: {
        $route: {
            handler(val, oldval) {
                // console.log(val.path.replace('/', ''));//新路由信息
                this.Indexy = val.path.replace('/', ''); //当前活动路由
            },
            // 深度观察监听
            deep: true,
        },
        profitablitylist: {
            handler(val, oldval) {
                this.profitablitylist = val;
            },
            deep: true,
        },
        yearslist: {
            handler(val, oldval) {
                //  console.log(val);
                this.yearslist = val;
                this.getyears();
                //  console.log(oldval);
            },
            deep: true,
        },
    },
    mounted() {
        this.getyears();
    },

    methods: {
        More() {
            this.moreclose = true;
        },
        closmoreDialog(value) {
            this.moreclose = value;
        },
        getyears() {
            // console.log(this.yearslist);
            if (this.windows.innerWidth > 821) {
                this.checkedCities = this.yearslist.slice(0, 3);
            } else {
                this.indexlist = [0];
                this.checkedCities = this.yearslist.slice(0, 1);
            }

            var lis = [];
            this.checkedCities.map(item => {
                lis.push(item.slice(0, 4));
            });

            this.years = lis.sort(function (a, b) {
                return b - a;
            });
        },
        checkYear() {
            var list = [];
            this.checkedCities.map(item => {
                list.push(item.slice(0, 4));
            });

            this.years = list.sort(function (a, b) {
                return b - a;
            });

            this.indexlist = [];
            this.yearslist.map((item, index) => {
                this.checkedCities.map((ite, inde) => {
                    if (item == ite) {
                        this.indexlist.push(index);
                    }
                });
            });
            this.createDialog = false;
        },
    },
};
</script>
<style scoped lang="less">
// 表格
.td {
    margin: 15px 0 0 25px;
}
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.rightBox.leftBox {
    display: inline-block;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.changeBox {
    display: flex;
    align-items: center;
}

.block13 {
    margin: 15px 10px 0 25px;
}

.thead {
    background: #f2f5f9ff;
    z-index: 405;
    height: 50px;
    position: relative;
}
.tbody {
    z-index: 405;
    height: 50px;
    position: relative;
    border-bottom: 1px solid #e7eaedff;
}
.thead-img {
    width: 30px;
    height: 30px;
    background: #8497ab;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    position: relative;
    top: -5px;
}
.td-width {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Arial Bold';
}
.table-tbody {
    position: relative;
}

.juli {
    margin-left: 30px;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.table-biao {
    // height: 340px;
    height: auto;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 14px;
    border: 1px solid #e7eaed;
}
.zhongjian {
    display: flex;
    justify-content: center;
}
.td-width-span {
    flex: 1;
    // text-align: center;
}
.td-width-txt {
    display: flex;
    // justify-content: center;
}
.el-checkbox-group {
    // display: flex;
    //   flex-wrap: wrap;
    //   justify-content: space-between;
}
.el-checkbox {
    margin-bottom: 15px;
    margin-left: 40px;
    margin-right: 0;
}
/deep/.el-checkbox__label {
    color: #022955ff !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
    color: #022955ff !important;
}
// /deep/ .el-checkbox__inner::after {
//   box-sizing: content-box;
//     content: "";
//     border: 1px solid #FFF;
//     border-left: 0;
//     border-top: 0;
//     height: 10px;
//     left: 8px;
//     position: absolute;
//     top: 1px;
//     transform: rotate(45deg) scaleY(0);
//     width: 3px;
// }

// /deep/.el-checkbox__inner{
//   background-color: #022955FF !important;
// }
@media (max-width: 821px) {
    .td-width {
        font-size: 14px !important;
    }
     .phone_flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .phone_flex .btn_export {
        width: 280px;
        border-radius: 30px;
        margin: 20px;
    }
}
</style>
